import { useEffect } from "react";
import { Notification } from "@shared/interfaces";
import { useToast } from "@chakra-ui/react";

export default function useNotification(notification: Notification | null) {
  const toast = useToast();
  useEffect(() => {
    if (notification) {
      toast({
        title: notification.appearance.toUpperCase(),
        description: notification.message,
        status: notification.appearance,
        duration: 5000,
        isClosable: true,
        position: "bottom-left",
      });
    }
  }, [notification, toast]);
}
